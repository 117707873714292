import React, { useEffect } from 'react'

// import { Container } from './styles';

const TransactionSearch: React.FC = () => {
  useEffect(() => {
    window.location.href = process.env.REACT_APP_TRANSACTION_SEARCH_URL || ''
  }, [])

  return <div />
}

export default TransactionSearch
